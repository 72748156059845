import { loadStripe } from '@stripe/stripe-js'
import Api from '../backend'

const isBrowser = typeof window !== "undefined"

export class Stripe {
  stripePromise = isBrowser ? loadStripe(process.env.STRIPE) : null

  complete = (sessionId: string) => {
    Api.postCheckoutComplete({ sessionId })
  }

  pay = () => {
    if (!isBrowser) {
      return
    }

    const run = new Promise<any>(async (resolve, reject) => {
      try {
        // Get Stripe.js instance
        const s = await stripe.stripePromise

        // Call your backend to create the Checkout Session
        Api.postCheckout({}).then(async (response) => {
          const session = response
  
          // When the customer clicks on the button, redirect them to Checkout.
          const result = await s.redirectToCheckout({
            sessionId: session.id,
          })
          if (result.error) {
            reject(result)
          }
          else {
            resolve(result)
          }
        }).catch((e) => {
          reject(e)
        })
      }
      catch {
        reject({})
      }
    })

    run.then((result) => {
      // ignore
    }).catch((result) => {
      // ignore
      console.error(result)
    })
  }
}

const stripe = new Stripe()
export default stripe

import { navigate } from 'gatsby'
import React from 'react'
import stripe from '../utils/stripe'

const isBrowser = typeof window !== "undefined"

const Callback = () => {
  if (isBrowser) {
    const url = (location && location.href) ? location.href : ''
    const index = url.indexOf('?session_id=')
    if (index >= 0) {
      stripe.complete(url.substr(index).replace('?session_id=', ''))
    }
    
    navigate('/meinbereich/')
  }

  return <p>Lade...</p>
}

export default Callback
